import * as React from 'react';
import SEO from '../../components/global/seo';
import OtherLayout from '../../components/other/other-layout';
import { Link } from 'gatsby';
import FormationAngularImage from '../../components/formation/angular/image';
import './index.scss';

const FormationPage = () => (
  <OtherLayout className="formation-page">
    <SEO title="Formations" />

    <h1>Formations</h1>
    <p></p>

    <Link to="/formation/angular">
      <article className="formations-list-item">
        <div className="thumbnail">
          <FormationAngularImage/>
        </div>
        <div className="text">
          <header>
            <h2>Angular</h2>
            <p>5 jours</p>
          </header>
          <p>Par Thierry Chatel, premier <em>“Google Developer Expert”</em> sur Angular en Europe.</p>
        </div>
      </article>
    </Link>

  </OtherLayout>
);

export default FormationPage;
