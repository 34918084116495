import * as React from 'react';
import './home-footer.scss';
import LogoTextBaseline from '../global/logo-text-baseline';
import { Link } from 'gatsby';

const HomeFooter = () => (
  <footer>
    <section className="social">
      <h3>Suivez-nous</h3>
      <div className="social-flex">
        <div className="social-item">(LinkedIn)</div>
        <div className="social-item"><a href="https://twitter.com/obi2b">Twitter</a></div>
        <div className="social-item"><Link to="/blog/">Le Blog</Link></div>
      </div>
    </section>

    <section className="footer">
      <div className="logo"><LogoTextBaseline onDark={true}/></div>
      <p>
        <Link to="/mentions/">Mentions légales</Link>
        &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;
        <Link to="/contact/">Contact</Link>
      </p>
      <p></p>
      <p>Les cookies, on les mange, on n’en pose pas chez vous à votre insu.<br/>Souriez, vous
        n'êtes pas traqué.</p>
    </section>

  </footer>
);

export default HomeFooter;

