import * as React from 'react';
import * as PropTypes from 'prop-types';
import LogoTextBaseline from '../global/logo-text-baseline';
import { Link } from 'gatsby';
import './other-layout.scss';
import HomeFooter from '../home/home-footer';

const OtherLayout = ({ children, className }) => (
  <div className={'other-layout' + (className ? ' ' + className : '')}>
    <header>
      <div className="logo">
        <Link to="/">
          <LogoTextBaseline></LogoTextBaseline>
        </Link>
      </div>
      <nav>
        <Link to="/">Accueil</Link>
        <Link to="/solutions/">Solutions</Link>
        <Link to="/formation/">Formations</Link>
        <Link to="/contact/">Contact</Link>
        <Link to="/blog/">Blog</Link>
      </nav>
    </header>
    <main>{children}</main>
    <HomeFooter/>
  </div>
);

OtherLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default OtherLayout;

