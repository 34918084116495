import * as React from 'react';
import * as PropTypes from 'prop-types';
import './logo-text-baseline.scss';

const LogoTextBaseline = ({onDark}) => (
  <div className={onDark ? 'dark' : ''}>
    <div className="obi2b-logo">Obi<span className="obi2b-logo-grey">2b</span></div>
    <div className="obi2b-logo-baseline">Extraordinary<br/>Technology</div>
  </div>
);

LogoTextBaseline.defaultProps = {
  onDark: false,
};

LogoTextBaseline.propTypes = {
  onDark: PropTypes.bool,
};

export default LogoTextBaseline;

